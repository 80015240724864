<template>
<v-container fluid>
  <date-range @getDates="getSales"></date-range>
  <v-alert v-if="!Object.keys(products).length && loaded" type="info">
    <p>No data to display.</p>
  </v-alert>
    <v-btn
      class="ma-2"
      color="primary"
      @click="downloadSalesPerformance"
    >
      <v-icon>mdi-table</v-icon>
    </v-btn>
    <pre>{{  }}</pre>
  <v-card v-for="(product, index) of products" :key="index" class="mb-2">
    <v-card-title>{{index }} ({{product.total}})</v-card-title>
  <v-data-table
    :headers="headers"
    :items="product.products"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:[`item.image`]="{ item }">
      <v-img class="my-2" max-height="50px" max-width="50px"
          :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
        ></v-img>
    </template>
  </v-data-table>
  </v-card>
</v-container>
</template>

<script>
import ReportApi from '@/api/admin/report'
import DateRange from '@/components/DateRange'
import _ from 'lodash'
import xlsx from 'xlsx'

export default {
  components: {
    DateRange,
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      productSalesPerformance: [],
      products: [],
      sales: [],
      loaded: false,
      headers: [
        {
          text: '',
          width: '5%',
          value: 'index'
        },
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Total',
          width: '8%',
          align: 'end',
          value: 'numItems'
        },
        {
          text: 'Title',
          width: '60%',
          value: 'title'
        },
        {
          text: 'Artist',
          value: 'artist'
        },
        {
          text: 'SKU',
          value: 'sku'
        }
      ]
    }
  },
  methods: {
    async getSales (dates) {
      ReportApi.loadProductSales({ dateRange: dates }).then((results) => {
        this.sales = results.data
        this.products = _.groupBy(this.sales, 'category')
        for (const product in this.products) {
          let totalSalesItems = 0
          const ranked = this.products[product].map((item, index) => {
            totalSalesItems += item.numItems
            return { ...item, index: index + 1 }
          })
          this.products[product] = { total: totalSalesItems, products: ranked }
        }
        this.loaded = true
      })
    },
    downloadSalesPerformance () {
      const headers = [
          'sku',
          'category',
          'artistName',
          'title',
          'skuSequence'
      ]
      const workbook = xlsx.utils.book_new()
      const worksheet = xlsx.utils.json_to_sheet(this.sales, { header: headers })
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sales')
      xlsx.writeFile(workbook, 'PRODUCT-SALES.xlsx')
    },
  },
  async created () {
  }
}
</script>
